import styled from 'styled-components'

export const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  font-size: 20px;
  margin: 0px 10px 0px;
  font-weight: 400;
  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    color: #3e3e3e;
  }
  .brandLogo {
    margin-top: 0px;
    height: 35px;
  }
  .brandName {
    color: #888585;
    font-size: 12px;
  }
  .productLogo {
    height: 50px;
    margin-left: 5px;
  }
  .brandDivider {
    border-left: solid 1px #999999;
    height: 40px;
    margin-top: 5px;
  }
`
